import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import io from "socket.io-client";
import { useUser } from "../../utilities/helper";
import RateChangesLog from "../../components/RateChangesLog";

function TenantRateUpload(props) {
  const [message, setMessage] = useState("");
  const [logs, setLogs] = useState([]);
  const [changes, setChanges] = useState([])
  const myRef = useRef(null);
  const user = useUser();

  const executeScroll = () => {
    const parentElement = (myRef && myRef.current) || {};
    const childNodes = Array.from(parentElement.childNodes || []);

    if (parentElement && childNodes.length) {
      let lastElement = childNodes[childNodes.length - 1];
      lastElement.scrollIntoView();
    }
  };

  const getChanges = () => {
    axios.get('/api/tenant_rate/changes').then((response) => {
      let { data } = response
      setChanges(data)
    })
  }

  useEffect(() => {
    getChanges()
    const socket = io(window.location.origin, { rejectUnauthorized: false, transports: ["polling"] });
    socket.open();
    // Listen for incoming logs for tenant rate upload
    socket.on("tenant_rate_upload_event", ({ action, logInfo, isError }) => {
      if (action === "createLog") {
        if (logInfo.log === "Successfully uploaded CSV file!") {
          getChanges()
        }
        if (isError) {
          setMessage("Import error.");
        }
        logs.push(logInfo);
        setLogs([...logs]);
        executeScroll();
      }
    });
    // Listen for incoming logs for tenant rate upload item
    socket.on("tenant_rate_upload_item_event", ({ action, logInfo, isError }) => {
      if (action === "createLog") {
        if (logInfo.log === "Successfully processed rate changes!") {
          setMessage("Import Finished.");
          getChanges()
        }
        if (isError) {
          setMessage("Import error.");
        }
        logs.push(logInfo);
        setLogs([...logs]);
        executeScroll();
      }
    });
    return () => {
      socket.close();
    };
  }, []);

  const handleSubmitCSV = (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);

    axios
      .post("/api/tenant_rate/upload-csv", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setMessage("Successfully uploaded new tenant rates from the CSV!");
        getChanges()
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.data?.message) {
          alert(error.response.data.message);
        }
      });
  };

  const handleProcess = () => {
    const data = new FormData();
    data.append("uploaded_at", new Date());
    data.append("user_id", user.user.id);
    axios
      .post("/api/tenant_rate/process", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setMessage('Processing new tenant rates ...');
      })
      .catch((error) => {
        setMessage('Something went wrong.')
        console.log(error);
      });
  }

  const handleDiscard = () => {
    axios
      .post("/api/tenant_rate/discard", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        getChanges()
        setMessage("New tenant rates are discarded.");
      })
      .catch((error) => {
        setMessage('Something went wrong.')
        console.log(error);
      });
  }

  return (
    <div className="container my-4">
      <h1 className="text-center">Tenant Rate Upload</h1>
      <div className="row">
        <div className="col-12 text-center">
          <section className="section-preview" style={{ display: "inline-block" }}>
            <div className="input-group my-3">
              <div className="input-group-prepend">
                <span className="input-group-text">Upload</span>
              </div>
              <div className="custom-file">
                <input type="file" className="custom-file-input" accept=".csv" onChange={handleSubmitCSV} />
                <label className="custom-file-label">Choose CSV File</label>
              </div>
            </div>
            <a href="/Existing-Tenant-Rate-Increase-RM_V2.csv">Download Example CSV</a>
          </section>
        </div>
      </div>
      <RateChangesLog changes={changes} onClickProcess={handleProcess} onClickDiscard={handleDiscard} />
      <div className="row d-flex justify-content-center mt-2">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => props.history.push("/tenant-rate-upload/logs")}
        >
          View logs
        </button>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center text-danger">{message}</div>
      </div>
      {logs.length ? (
        <div className="row">
          <div className="log-content" ref={myRef}>
            {logs.map((item) => (
              <p key={item.id}>{item.log}</p>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default TenantRateUpload;
