import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactMde from "react-mde";
import * as Showdown from "showdown";
import { FaMinusCircle } from "react-icons/fa";

function CreateLandingPage(props) {
  const [locations, setLocations] = useState([]);
  const [facilitiesIds, setFacilitiesIds] = useState([]);

  const [keywords, setKeywords] = useState([]);

  const [body, setBody] = useState("");
  const [selectedTab, setSelectedTab] = useState("write");

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [shortName, setShortName] = useState("");
  const [fullName, setFullName] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [headerImageSrc, setHeaderImageSrc] = useState("");
  const [imageAlt, setImageAlt] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    const fetchLocations = () => {
      axios.get("/api/locations").then((res) => {
        setLocations(res.data || []);
      });
    };

    fetchLocations();
  }, []);

  const newKeyword = () => {
    setKeywords(keywords.concat(""));
  };

  const changeKeyword = (index, value) => {
    keywords[index] = value;
    setKeywords([...keywords]);
  };

  const removeKeyword = (index) => {
    keywords.splice(index, 1);
    setKeywords([...keywords]);
  };

  const handleSubmit = () => {
    // validate at lease one facility is selected
    if (facilitiesIds.length === 0) {
      alert("Please select at least one facility.");
      return;
    }

    const data = {
      short_name: shortName,
      full_name: fullName,
      meta_title: metaTitle,
      meta_description: metaDescription,
      header_image_src: headerImageSrc.trim(),
      image_alt: imageAlt,
      keywords: keywords,
      body: body,
      lat: lat,
      long: long,
      zoom_level: zoomLevel,
      facilities_ids: facilitiesIds,
    };

    axios
      .post(`/api/landing_pages`, data)
      .then((res) => {
        if (res.status === 200) {
          alert("Successfully created the landing page!");
        }
      })
      .catch((err) => {
        alert("Failed to create landing page.", err);
      });
  };

  return (
    <div className="container my-4">
      <h1 className="text-center">Create Landing Page Content</h1>

      <div className="row py-2">
        <div className="col-4 text-right">Short Name:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={shortName}
            onChange={(e) => setShortName(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Full Name:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Latitude:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input w-100" value={lat} onChange={(e) => setLat(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Longitude:</div>
        <div className="col-8 text-left">
          <input type="text" className="form-input w-100" value={long} onChange={(e) => setLong(e.target.value)} />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Zoom Level:</div>
        <div className="col-8 text-left">
          <input
            type="number"
            className="form-input"
            value={zoomLevel}
            onChange={(e) => setZoomLevel(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Meta Title:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={metaTitle}
            onChange={(e) => setMetaTitle(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Meta Description:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={metaDescription}
            onChange={(e) => setMetaDescription(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">keywords:</div>
        <div className="col-8 text-left">
          {keywords.map((keyword, index) => (
            <div key={"keyword" + index}>
              <input
                type="text"
                className="form-input mb-1 w-75"
                value={keyword}
                onChange={(e) => changeKeyword(index, e.target.value)}
              />
              <span
                className="ml-3 cursor-pointer action-btn text-danger"
                title="Delete Keyword"
                onClick={() => removeKeyword(index)}
              >
                <FaMinusCircle />
              </span>
            </div>
          ))}
          <button className="btn btn-primary" onClick={newKeyword}>
            Add New Keyword
          </button>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Image Location:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={headerImageSrc}
            onChange={(e) => setHeaderImageSrc(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Image Alt:</div>
        <div className="col-8 text-left">
          <input
            type="text"
            className="form-input w-100"
            value={imageAlt}
            onChange={(e) => setImageAlt(e.target.value)}
          />
        </div>
      </div>
      <div className="row py-2">
        <div className="col-4 text-right">Body:</div>
        <div className="col-8 text-left">
          <ReactMde
            value={body}
            onChange={setBody}
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
          />
        </div>
      </div>

      <div className="row py-2">
        <div className="col-4 text-right">Select facility:</div>
        <div className="col-8 text-left">
          {Array.from(Array(Math.ceil(locations.length / 2)).keys()).map((row) => (
            <div key={`row-${row}`} className="row py-2">
              {locations.slice(row * 2, (row + 1) * 2).map((location) => (
                <div key={location.id} className="col-6">
                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      value={location.id}
                      id={`location-${location.id}`}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setFacilitiesIds(facilitiesIds.concat(location.id));
                        } else {
                          setFacilitiesIds(facilitiesIds.filter((id) => id !== location.id));
                        }
                      }}
                    />
                    <label htmlFor={`location-${location.id}`} className="mb-0 ml-2">
                      {`${location.name} (${location.city}, ${location.state})`}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12 text-right">
          <Link className="btn btn-secondary px-4 mr-4" to="/landing-pages">
            Back
          </Link>
          <button className="btn btn-primary px-4 mr-4" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

export default CreateLandingPage;
