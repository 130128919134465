import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaTrash, FaPlus, FaTools } from "react-icons/fa";

function LandingPages() {
  const [landingPages, setLandingPages] = useState([]);

  const fetchLandingPages = () => {
    axios.get("/api/landing_pages").then((res) => {
      setLandingPages(res.data || []);
    });
  };

  const handleDeleteLandingPage = (shortName) => {
    if (window.confirm("Are you sure you want to delete this landing page?")) {
      axios.delete("/api/landing_pages/" + shortName).then((res) => {
        if (res.status === 200) {
          fetchLandingPages();
        }
      });
    }
  };

  useEffect(() => {
    fetchLandingPages();
  }, []);

  return (
    <div className="container-fluid mt-2">
      <div className="table-responsive">
        <div className="table-wrapper">
          <div className="table-title">
            <div className="row">
              <div className="col-sm-4">
                <h2>
                  <b>Landing Pages</b>
                </h2>
              </div>
              <div className="col-sm-8">
                <Link className="btn btn-info add-new float-right" title="New Landing Page" to={`/landing-pages/new`}>
                  <FaPlus /> New Landing Page
                </Link>
              </div>
            </div>
          </div>
          <table className="table table-striped table-hover table-bordered bg-white">
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Short Name</th>
                <th>Full Name</th>
                <th>Header Image</th>
                <th className="text-center" style={{ width: "120px" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {landingPages.map((landingPage, index) => (
                <tr key={landingPage.short_name}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{landingPage.short_name}</td>
                  <td>{landingPage.full_name}</td>
                  <td>{landingPage.header_image_src}</td>
                  <td className="text-center actions">
                    <span
                      className="action-btn text-danger px-1"
                      title="Delete"
                      onClick={() => handleDeleteLandingPage(landingPage.short_name)}
                    >
                      <FaTrash />
                    </span>
                    <Link
                      className="action-btn text-success px-1"
                      title="Edit CMS Content"
                      to={`/landing-pages/${landingPage.short_name}`}
                    >
                      <FaTools />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default LandingPages;
